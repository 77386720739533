import { GetStaticPropsContext, NextPage } from 'next'
import Link from 'next/link'

import GoogleSeoSchoolScript from '~components/scripts/googleSeoSchool'
import GoogleFaqSchoolScript from '~components/scripts/googleFaqSchool'
import Image from 'next/image'
import styles from '~styles/school.module.scss'
import useTranslation from 'next-translate/useTranslation'

import { FetchOnDemandCategoryDocument, FetchOnDemandCategoryQuery } from '~graphql/types'
import { query } from '~lib/graphql'

import Head from 'next/head'
import React, { FormEventHandler, Fragment, useState } from 'react'

export async function getStaticProps({ locale }: GetStaticPropsContext) {
    const data = await query(FetchOnDemandCategoryDocument, locale || 'fr')

    return {
        props: { data }, // will be passed to the page component as props
        revalidate: process.env.NODE_ENV === 'development' ? 10 : 300, // In seconds
    }
}
const Page: NextPage<{ data: FetchOnDemandCategoryQuery }> = ({ data }) => {
    const { category } = data

    const { t } = useTranslation('school')

    const [formSubmitted, setFormSubmitted] = useState(false)

    const submitContactForm: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault()

        const response = await fetch('/api/school/getNotified', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(Object.fromEntries(new FormData(e.currentTarget))),
        })

        const data = await response.json()

        if (response.ok) {
            setFormSubmitted(true)
        } else {
            alert(data.errorMessage)
        }
    }

    return (
        <main className={styles.school}>
            <GoogleSeoSchoolScript />
            <GoogleFaqSchoolScript />
            <Head>
                <title>{category?.seoTitle || t`school.landing.header-title`}</title>
                <meta property="og:site_name" content={t`school.landing.site-name`} />
                <meta property="og:description" content={t`school.landing.about-description`} />
                <meta property="og:title" content={t`school.landing.header-title`} />
                <meta property="og:url" content={process.env.NEXT_PUBLIC_SCHOOL_URL} />
                <meta property="og:type" content="website" />
                <meta
                    property="og:image"
                    content={process.env.NEXT_PUBLIC_SCHOOL_URL + '/images/school/cover_link_fizzup_school.jpg'}
                />
                <meta name="description" content={t`school.landing.about-description`} />
                <meta name="theme-color" content={'#0957FF'} />
                <link rel="apple-touch-icon" href="//cdn.fizzup.com/228/logo.png" />
                <link rel="manifest" href="/school-manifest.json" />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="//cdn.fizzup.com/full/landing_0/images/favicon_v3/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="96x96"
                    href="//cdn.fizzup.com/full/landing_0/images/favicon_v3/favicon-96x96.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="//cdn.fizzup.com/full/landing_0/images/favicon_v3/favicon-16x16.png"
                />
            </Head>
            <Image
                layout={'raw'}
                width={316}
                height={60}
                className={styles.logo}
                src="/images/school/fizzup_x_sielbleu_logo_web.png"
                alt="Fizzup School"
            />
            <header>
                <picture>
                    <Image
                        layout={'fill'}
                        objectFit="cover"
                        objectPosition="left"
                        src="/images/school/fizzup-ecole.webp"
                        alt={category?.seoTitle || t`school.landing.header-title`}
                    />
                </picture>
                <div>
                    <h1>{t`school.landing.header-title`}</h1>
                    <h2>{t`school.landing.header-subtitle`}</h2>
                    <>
                        {category?.children?.map((child, index) => (
                            <Link href={`/on-demand/${child.slug}`} key={index}>
                                <a>
                                    <button>{child.name}</button>
                                </a>
                            </Link>
                        ))}
                    </>
                </div>
            </header>

            <section>
                <h2>{t`school.landing.about-title`}</h2>
                <p>{t`school.landing.about-description`}</p>

                <ul className={styles.cards}>
                    <li>
                        <Image
                            layout={'raw'}
                            width={198}
                            height={198}
                            style={{ display: 'inline' }}
                            src="/images/school/circuits_activites_icon_web.svg"
                            alt={t`school.landing.about-activity-title`}
                        />
                        <h3>{t`school.landing.about-activity-title`}</h3>
                        {t`school.landing.about-activity-description`}
                    </li>
                    <li>
                        <Image
                            layout={'raw'}
                            width={198}
                            height={198}
                            style={{ display: 'inline' }}
                            src="/images/school/contenus_adaptes_icon_web.svg"
                            alt={t`school.landing.about-content-title`}
                        />
                        <h3>{t`school.landing.about-content-title`}</h3>
                        {t`school.landing.about-content-description`}
                    </li>
                    <li>
                        <Image
                            layout={'raw'}
                            width={198}
                            height={198}
                            style={{ display: 'inline' }}
                            src="/images/school/seances_5-30_icon_web.svg"
                            alt={t`school.landing.about-duration-title`}
                        />
                        <h3>{t`school.landing.about-duration-title`}</h3>
                        {t`school.landing.about-duration-description`}
                    </li>
                </ul>
            </section>

            <picture>
                <source
                    type="image/webp"
                    media="(max-width: 540px)"
                    srcSet="/images/school/bandeau_img_dev_service_webmobile.webp, /images/school/bandeau_img_dev_service_webmobile@2x.webp 2x"
                />
                <source
                    media="(max-width: 540px)"
                    srcSet="/images/school/bandeau_img_dev_service_webmobile.jpg, /images/school/bandeau_img_dev_service_webmobile@2x.jpg 2x"
                />
                <source
                    type="image/webp"
                    srcSet="/images/school/bandeau_img_dev_service_web.webp, /images/school/bandeau_img_dev_service_web@2x.webp 2x"
                />
                <img
                    src="/images/school/bandeau_img_dev_service_web.jpg"
                    srcSet="/images/school/bandeau_img_dev_service_web@2x.jpg 2x"
                    style={{ width: '100%' }}
                    alt={'school.landing.funding-title'}
                />
            </picture>

            <section>
                <h2>{t`school.landing.funding-title`}</h2>
                <p>{t`school.landing.funding-description`}</p>
                <button>
                    <a href={'https://fr.tipeee.com/fizzup-school/'} target="_blank" rel="noreferrer nofollow">
                        {t`school.landing.funding-cta`}
                    </a>
                </button>
            </section>

            <aside>
                {formSubmitted ? (
                    t`school.landing.contact-box-submitted`
                ) : (
                    <Fragment>
                        <strong>{t`school.landing.contact-box-title`}</strong>
                        <form onSubmit={submitContactForm}>
                            <input type="email" placeholder={t`school.landing.contact-box-placeholder`} name="email" />
                            <input type="submit" value={t`school.landing.contact-box-cta`} />
                        </form>
                    </Fragment>
                )}
            </aside>

            <section>
                <h2>{t`school.landing.faq-title`}</h2>
                <div className={styles.tabs}>
                    {Array(10)
                        .fill(null)
                        .map((_, id) => 'school.landing.faq-questions.' + id)
                        .map((prefix, id) => {
                            const title = t(prefix + '.title')
                            const content = t(prefix + '.content')

                            if (title === prefix + '.title' || content === prefix + '.content') {
                                // Question is missing from translation file, we ignore it.
                                return null
                            }

                            return (
                                <details key={id}>
                                    <summary>{title}</summary>
                                    <Fragment>
                                        {content.split(/\n\n/).map((line, id) => (
                                            <p key={id}>{line}</p>
                                        ))}
                                    </Fragment>
                                </details>
                            )
                        })}
                </div>
            </section>

            <footer>
                <div className={styles.container}>
                    <div className={styles.row}>
                        <div className={styles.footerMenus}>
                            <div className={styles.footerLogo}>
                                <a href="https://www.fizzup.com/" target={'_self'}>
                                    <img
                                        src="https://cdn.fizzup.com/full/footer/logo_fizzup_footer_web.png"
                                        srcSet="https://cdn.fizzup.com/full/footer/logo_fizzup_footer_web@2x.png 2x"
                                    />
                                </a>
                                <p>© 2024 FizzUp SAS</p>
                            </div>
                            <nav>
                                <p>ENTRAINEMENTS</p>
                                <ul className={styles.menuLinks}>
                                    <li>
                                        <a href="https://www.fizzup.com/fr/programme-entrainement/" target="">
                                            Programmes
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://conseils.fizzup.com/" target="">
                                            Conseils
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.fizzup.com/fr/fizzup-pour-les-entreprises/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            FizzUp Entreprises
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <nav>
                                <p>SOCIÉTÉ</p>
                                <ul className={styles.menuLinks}>
                                    <li>
                                        <a href="https://conseils.fizzup.com/a-propos/" target="">
                                            À propos
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://conseils.fizzup.com/presse/" target="">
                                            Presse
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://conseils.fizzup.com/jobs/" target="">
                                            Emploi
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://shop.fizzup.com/" target="">
                                            Cartes cadeaux
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <nav>
                                <p>LEGAL</p>
                                <ul className={styles.menuLinks}>
                                    <li>
                                        <a
                                            href="https://app.fizzup.com/conditions-generales.pdf"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            CGV
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://app.fizzup.com/politique-de-confidentialite.pdf"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Politique de confidentialité
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://app.fizzup.com/mentions-legales.pdf"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Mentions légales
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://fizzup.atlassian.net/servicedesk/customer/portal/1"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Centre d&apos;aide
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <nav>
                                <p>RÉSEAUX SOCIAUX</p>
                                <ul className={styles.socialMenu}>
                                    <li>
                                        <a href="https://www.facebook.com/FizzUpFR/" target="_blank" rel="noreferrer">
                                            <img src="https://cdn.fizzup.com/full/footer/facebook.svg" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/fizzup/" target="_blank" rel="noreferrer">
                                            <img src="https://cdn.fizzup.com/full/footer/instagram.svg" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://fr.linkedin.com/company/fizzup"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img src="https://cdn.fizzup.com/full/footer/linkedin.svg" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.youtube.com/@FizzUpCoaching"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img src="https://cdn.fizzup.com/full/footer/youtube.svg" />
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div className={styles.footerApp}>
                            <a
                                href="https://apps.apple.com/fr/app/fizzup-fitness-musculation/id723322796"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src="https://cdn.fizzup.com/full/footer/badge_app_store_web.png"
                                    srcSet="https://cdn.fizzup.com/full/footer/badge_app_store_web@2x.png 2x"
                                    className="img-fluid"
                                    alt="App Store"
                                />
                            </a>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.fysiki.fizzup"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src="https://cdn.fizzup.com/full/footer/badge_google_play_web.png"
                                    srcSet="https://cdn.fizzup.com/full/footer/badge_google_play_web@2x.png 2x"
                                    className="img-fluid"
                                    alt="Google Play"
                                />
                            </a>
                            <a href="https://appgallery.huawei.com/app/C101497771" target="_blank" rel="noreferrer">
                                <img
                                    src="https://cdn.fizzup.com/full/footer/badge_app_gallery_web.png"
                                    srcSet="https://cdn.fizzup.com/full/footer/badge_app_gallery_web@2x.png 2x"
                                    className="img-fluid"
                                    alt="Huawei - AppGallery"
                                />
                            </a>
                            <a href="https://www.fizzup.com/" target="_blank" rel="noreferrer">
                                <img
                                    src="https://cdn.fizzup.com/full/footer/badge_web_web.png"
                                    srcSet="https://cdn.fizzup.com/full/footer/badge_web_web@2x.png 2x"
                                    className="img-fluid"
                                    alt="Web app"
                                />
                            </a>
                            <img
                                src="https://cdn.fizzup.com/full/footer/badge_web_android_tv.png"
                                srcSet="https://cdn.fizzup.com/full/footer/badge_web_android_tv@2x.png 2x"
                                className="img-fluid"
                                alt="Android TV"
                            />
                        </div>
                    </div>
                </div>
            </footer>
        </main>
    )
}

export default Page
