import Script from 'next/script'
import { FunctionComponent } from 'react'

const Component: FunctionComponent = () =>
    'production' === process.env.NODE_ENV ? (
        <Script id="googleFaq" type="application/ld+json">
            {`
              {
              "@type": "FAQPage",
              "@context": "https://schema.org",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "Pourquoi faire du sport à l'école ?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Le développement d'une activité physique à l'école constitue un enjeu de santé et de bien-être essentiel. Une pratique sportive régulière permet notamment de lutter contre la sédentarité et l'anxiété, tout en favorisant le développement des capacités motrices et des aptitudes physiques des enfants."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Quel sport à l'école ?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "FizzUp School été pensé pour contribuer à un développement physique optimal pour les enfants avec des exercices de motricité qui permettent d'améliorer la coordination, de la proprioception pour améliorer l'équilibre, du cardio adapté pour se défouler, des exercices de respiration et de stretching pour décompresser ou encore de la danse pour bouger en s'amusant."
                  }
                },
                {
                  "@type": "Question",
                  "name": "L'activité sportive est-elle adaptée ?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>L'activité physique proposée dans FizzUp School se compose d'exercices et de séances totalement adaptés à l'âge et au niveau des enfants. FizzUp School propose une bibliothèque d'exercices pouvant être utilisés du CP jusqu'au CM2.</p> <p>FizzUp School s'adapte également à l'environnement de l'école : il n'est pas nécessaire d'avoir une tenue de sport pour réaliser les différents exercices et ces derniers peuvent facilement être réalisés dans une salle de classe ou dans la cour de récréation.</p>"
                  }
                },
                {
                  "@type": "Question",
                  "name": "Quel tarif pour FizzUp School ?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "C'est totalement gratuit ! FizzUp School s'inscrit dans une démarche bénévole en souhaitant encourager la pratique d'une activité physique régulière dès le plus jeune âge et de manière égalitaire."
                  }
                }
              ]
            }
        `}
        </Script>
    ) : null

export default Component
