import Script from 'next/script'
import { FunctionComponent } from 'react'

const Component: FunctionComponent = () =>
    'production' === process.env.NODE_ENV ? (
        <Script id="googleSeo" type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "FizzUp",
              "url": "https://www.fizzup-ecole.com/",
              "logo": {
                "@type": "ImageObject",
                "url": "//cdn.fizzup.com/full/landing_0/images/logo-fizzup.png"
              },
              "sameAs": [
                "https://www.facebook.com/FizzUpFR/",
                "https://fr.linkedin.com/company/fizzup",
                "https://www.instagram.com/fizzup/",
                "https://twitter.com/FizzUp_FR",
                "https://www.youtube.com/@FizzUpCoaching"
              ]
            }
        `}
        </Script>
    ) : null

export default Component
